/**
 * Line-Up ticketing widget loader
 *
 * This is a lightweight app that should not be cached, it loads the actual
 * ticketing application by looking for the embed code tags on the page.
 *
 * An instance of the LineupTicketingLoader class is exposed via the DOM, so
 * that the ticketing app can be programatically started if needed.
 *
 */
import domready from "domready";
import iFrameResizer from "iframe-resizer";
import loaderCss from "bundle-text:./loader.css";
import loaderHtml from "bundle-text:./loader.html";

const IFRAME_URL = process.env.CHECKOUT_IFRAME_URL || "";
const MIGRATED_URLS = [
  "file:///Users/barnyclark/Desktop/test.html",
  "http://localhost:64997/",
];
class LineupTicketingLoader {
  constructor() {
    this.widgetCount = 0;
    this.renderedClassName = "lineup-ticketing-rendered";
    this.baseId = "lineup-ticketing-embed-";
    this.basePayloadId = "lineup-ticketing-payload-";
    this.loaded = false;
    this.luClassName = "lineup-ticketing";
    this.styleTagAdded = false;
    this.initWidget();
  }

  initWidget() {
    if (this.loaded) {
      return;
    }
    this.loaded = true;

    if (MIGRATED_URLS.includes(window.location.href)) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://loader.line-up.tickets/checkout-loader.js";
      document.head.appendChild(script);
      var widget = document.getElementsByClassName(this.luClassName)[0];
      widget.removeChild(widget.firstChild);
      widget.setAttribute(
        "data-lineup-api-key",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGFubmVsX2lkIjoiMiIsInNjb3BlcyI6WyJ0cmFuc2FjdGlvbl9yZWFkIiwidHJhbnNhY3Rpb25fd3JpdGUiLCJldmVudF9yZWFkIiwicGVyZm9ybWFuY2VfcmVhZCIsImN1c3RvbWVyX3dyaXRlIl0sImFtciI6WyJtZmEiXSwidHlwZSI6InRyYW5zYWN0aW9uIiwic3ViIjoiMiJ9.HDzxqip6nfWTAUU2ubQ2JtYzWJusq9S0qAy7vkZUu9k"
      );
      widget.setAttribute("data-lineup-event-id", "1");
      document.head.appendChild(script);
    } else {
      domready(() => this.setupWidgets());
    }
  }

  /**
   * Find all lineup-ticketing tags on the page and setup the iframe
   *
   */
  setupWidgets() {
    let widgets = document.getElementsByClassName(this.luClassName);

    // current lineup-ticketing div embed code
    for (let idx = 0; idx < widgets.length; idx++) {
      let widget = widgets[idx];
      if (widget.tagName === "DIV") {
        let options = this.parseOptions(widget);
        this.insertIFrame(widget, options);
      }
    }
  }

  /**
   * Convert a object with key-value pairs to a url encoded string
   *
   */
  serialize(obj) {
    let str = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  /**
   * Parse the data-* attributes and return them as a key-value object
   *
   */
  parseOptions(widget) {
    const apiKey = widget.getAttribute("data-lineup-api-key");
    const eventId = widget.getAttribute("data-lineup-event-id");
    const width = widget.getAttribute("data-lineup-plugin-width") || "100%";
    const height = widget.getAttribute("data-lineup-plugin-height") || "100%";
    const mainColor =
      widget.getAttribute("data-lineup-main-color") || "#32898f";
    const bgColor =
      widget.getAttribute("data-lineup-background-color") || "#ffffff";
    const performanceId = widget.getAttribute("data-lineup-performance-id");
    const listingsLink = widget.getAttribute("data-lineup-listings-link");
    let collections = widget.getAttribute("data-lineup-collections");
    const collection = widget.getAttribute("data-lineup-collection-id");
    const formId = widget.getAttribute("data-lineup-form-id");
    let singlePerformance = widget.getAttribute(
      "data-lineup-single-performance"
    );
    const lastDate = widget.getAttribute("data-lineup-last-date");
    const firstDate = widget.getAttribute("data-lineup-first-date");
    if (singlePerformance === null) {
      if (performanceId !== null) {
        singlePerformance = true;
      } else {
        singlePerformance = false;
      }
    }
    if (collection && !collections) {
      collections = `[${collection}]`;
    }

    let options = {
      apiKey: apiKey,
      eventId: eventId,
      height: height,
      width: width,
      mainColor: mainColor,
      backgroundColor: bgColor,
      performanceId: performanceId,
      singlePerformance: singlePerformance,
      listingsLink: listingsLink,
      collections: collections,
      customFormId: formId,
      lastDate: lastDate,
      firstDate: firstDate,
    };

    // remove any null values from the options list
    for (let key in options) {
      if (options[key] === null) {
        delete options[key];
      }
    }

    return options;
  }

  /**
   * Insert the stylesheet for the loading indicator
   *
   */
  insertLoaderStylesheet(options) {
    if (this.styleTagAdded) {
      return;
    }

    let head = document.getElementsByTagName("head")[0];
    let style = document.createElement("style");

    let reExp = "[$]mainColor";
    let css = loaderCss.replace(new RegExp(reExp, "g"), options.mainColor);

    style.innerHTML = css;
    head.appendChild(style);
    this.styleTagAdded = true;
  }

  /**
   * Get the html for the loader
   *
   * @param {object} options The options passed to the widget
   */
  getLoaderHtml(options) {
    let html = loaderHtml;
    for (let key in options) {
      let reExp = "[$]" + key;
      html = html.replace(new RegExp(reExp, "g"), options[key]);
    }

    return html;
  }

  /**
   * Create the iframe and embed it into the page
   *
   */
  insertIFrame(widget, options) {
    const widgetNumber = this.widgetCount++;
    const widgetId = this.baseId + widgetNumber;
    const iframe = document.createElement("iframe");

    if (!widget.id) {
      widget.id = this.basePayloadId + widgetNumber;
    }

    this.insertLoaderStylesheet(options);

    widget.innerHTML = this.getLoaderHtml(options);

    iframe.className = widget.className;
    iframe.id = widgetId;
    iframe.src = IFRAME_URL + "?" + this.serialize(options);
    iframe.setAttribute("allowTransparency", true);
    iframe.setAttribute("frameBorder", 0);
    iframe.setAttribute("height", options.height);
    iframe.setAttribute("width", options.width);
    iframe.setAttribute("style", "display: none;"); // hide the iframe while loading

    widget.appendChild(iframe);
    widget.className = this.renderedClassName;

    this.setupIFrameResizer(iframe, widget);
  }

  setupIFrameResizer(iframe, containerElement) {
    const options = {
      log: false,
      heightCalculationMethod: "lowestElement",
      resizeFrom: "child",
      targetOrigin: "*",
      checkOrigin: false,
      onResized: this.getResizeCallback(iframe, containerElement),
      onMessage: this.getOnPurchaseCallback(containerElement),
    };

    iFrameResizer.iframeResize(options, iframe);
  }

  getOnPurchaseCallback(containerEl) {
    const onPurchaseCallback = containerEl.getAttribute(
      "data-lineup-on-purchase"
    );
    const onAddToBasketCallback = containerEl.getAttribute(
      "data-lineup-on-add-basket"
    );
    return (messageData) => {
      const { orderId, orderTotal, order } = messageData.message;
      if (messageData.message.messageType === "orderSuccess") {
        if (onPurchaseCallback && onPurchaseCallback in window) {
          window[onPurchaseCallback](orderId, orderTotal, order);
        }
      } else if (messageData.message.messageType === "addToBasket") {
        if (onAddToBasketCallback && onAddToBasketCallback in window) {
          window[onAddToBasketCallback](orderId, orderTotal, order);
        }
      } else if (messageData.message.messageType === "tracker") {
        if (window._lineupTracker) {
          window._lineupTracker.track(messageData.message);
        }
      }
    };
  }
  /**
   * Returns a callback which is executed for the initial resize
   *
   * @param {object} iframeEl The iframe DOM element
   * @param {object} containerEl The container of the iframe
   *
   */
  getResizeCallback(iframeEl, containerEl) {
    let loaderHidden = false;
    return (resizeData) => {
      if (loaderHidden) {
        return;
      }

      if (resizeData.height > 50) {
        containerEl.removeChild(containerEl.firstChild);
        loaderHidden = true;
      }
      iframeEl.style["display"] = "block";
    };
  }

  /**
   * Start a single instance of the app
   *
   * @param {string} elementId The Id of the element which will host the app
   * @param {object} options description
   *
   */
  startIFrameApplication(elementId, options) {
    const rootNode = document.getElementById(elementId);
    if (!rootNode) {
      throw new Error("Could not find element to attach to");
    }

    this.insertIFrame(rootNode, options);
  }

  /**
   * Removes the iframe app from the DOM
   *
   * @param {string} elementId The ID of the element which hosts the app
   *
   */
  stopIFrameApplication(elementId) {
    const rootNode = document.getElementById(elementId);
    if (!rootNode) {
      return false;
    }

    const iframeNode = rootNode.getElementsByTagName("iframe")[0];
    if (!iframeNode) {
      return false;
    }

    rootNode.removeChild(iframeNode);
    return true;
  }
}

if (!window.LineupTicketingLoader) {
  window.LineupTicketingLoader = new LineupTicketingLoader();
}
